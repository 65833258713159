// .input {
//   border: 2px solid #414141;
//   border-radius: 19px;
//   padding: 0px 5px;
//   font-family: Roboto-Bold;
//   // width: auto;
//   display: flex;
//   max-width: 30px;
//   text-align: center;
//   height: 33px;
//   margin-right: 10px;
// }

// .headerButton {
//   height: 33px;
//   border-radius: 19px;
//   cursor: pointer;
//   white-space: nowrap;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0 20px;
// }

// .unpressedButton {
//   border: 1px solid #C4C4C4;
//   background-color: #C4C4C4;
//   color: #fff;
//   font-family: Roboto-Regular;
//   font-size: 15px;
// }

// .pressedButton {
//   background-color: #0ACF83;
//   border: 1px solid #0ACF83;
//   font-family: Heebo-Bold;
//   font-size: 15px;
//   color: #FFFFFF;
// }
///--------------------------------------

.dayGrowthContainer {
  display: flex;
  flex-direction: column;
  width: 139px!important;
}
.growDayRow {
  & > label {
    font-size: 12px;
    font-family: Roboto-Bold;
    color: #414141;
  } 

  .content {
    width: 139px;
    // justify-content: space-between;
    height: 150px;
    overflow-y: scroll;
    direction: rtl;
    &::-webkit-scrollbar {
      width: 10px; /* Толщина скроллбара */
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Цвет фона */
    }
    
    &::-webkit-scrollbar-thumb {
      background: #888; /* Цвет ползунка */
      border-radius: 5px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #555; /* Цвет при наведении */
    }
    .containerContent {
      direction: ltr;
      display: flex;
      flex-wrap: wrap;
    }
    .day {
      width: 24px;
      height: 24px;
      font-size: 14px;
      background: #9BA7A2;
      color: #414141;
      font-family: Roboto-Bold;
      border-radius: 4px;
      margin: 4px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
      &.active {
        background: #414141;
        color: #E3E0E0;
      }
      &.disabled {
        background: #E3E0E0;
        color: #9BA7A2;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

.growDayChikenRow {
  display: flex;
  padding: 2px 10px 2px 4px;
  border-radius: 9px;
  background: #414141;
  align-items: center;
  width: 120px;
  margin-left: 13px;
  margin-top: 6px;
  .input {
    font-size: 12px;
    color: #414141;
    font-family: "Heebo-Bold";
    max-width: 46px;
    width: 46px;
    height: 21px;
    border: 0;
    background: #9BA7A2;
    border-radius: 7px;
    margin-right: 6px;
    padding: 2px;
    text-align: center;
  }
  .confirm {
    color: #fff;
    cursor: pointer;
    font-family: Heebo-Regular;
    font-size: 15 px;
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  // .input {
  //   border: 2px solid #414141;
  //   border-radius: 15px;
  //   padding: 0px 4px;
  //   max-width: 24px;
  //   height: 26.5px;
  //   margin-right: 8px;
  // }
  
  // .headerButton {
  //   height: 33px;
  //   border-radius: 26.5px;
  //   padding: 0 16px;
  // }
  
  // .unpressedButton {
  //   font-size: 12px;
  // }
  
  // .pressedButton {
  //   font-size: 12px;
  // }

  .dayGrowthContainer {
    width: 111px!important;
  }
  .growDayRow {
  
    .content {
      width: 111px;
      height: 120px;
      &::-webkit-scrollbar {
        width: 8px; /* Толщина скроллбара */
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
      }
      
      .day {
        width: 19px;
        height: 19px;
        font-size: 12px;
        border-radius: 3px;
        margin: 3px;
      }
    }
  }
  
  .growDayChikenRow {
    padding: 1.5px 8px 1.5px 3px;
    border-radius: 7px;
    width: 96px;
    margin-left: 10.5px;
    margin-top: 5px;
    .input {
      font-size: 12px;
      max-width: 37px;
      width: 37px;
      height: 17px;
      border-radius: 5.5px;
      margin-right: 5px;
      padding: 1.5px;
    }
    .confirm {
      font-size: 12px;
    }
  }
}
  
@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  // .input {
  //   border: 1px solid #414141;
  //   border-radius: 13px;
  //   padding: 0px 3.5px;
  //   max-width: 21px;
  //   height: 23px;
  //   margin-right: 7px;
  // }
  
  // .headerButton {
  //   height: 23px;
  //   border-radius: 13px;
  //   padding: 0 14px;
  // }
  
  // .unpressedButton {
  //   font-size: 10.5px;
  // }
  
  // .pressedButton {
  //   font-size: 10.5px;
  // }
  .dayGrowthContainer {
    width: 97.5px!important;
  }
  .growDayRow {
  
    .content {
      width: 97.5px;
      height: 105px;
      &::-webkit-scrollbar {
        width: 7px; /* Толщина скроллбара */
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
      }
      
      .day {
        width: 17px;
        height: 17px;
        font-size: 12px;
        border-radius: 3px;
        margin: 3px;
      }
    }
  }
  
  .growDayChikenRow {
    padding: 1.5px 7px 1.5px 3px;
    border-radius: 6.5px;
    width: 84px;
    margin-left: 9px;
    margin-top: 4px;
    .input {
      font-size: 12px;
      max-width: 32px;
      width: 32px;
      height: 15px;
      border-radius: 5px;
      margin-right: 4px;
      padding: 1.5px;
    }
    .confirm {
      font-size: 12px;
    }
  }
}
  
@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  // .input {
  //   border: 1px solid #414141;
  //   border-radius: 10.5px;
  //   padding: 0px 3px;
  //   max-width: 16.5px;
  //   height: 18px;
  //   margin-right: 5.5px;
  // }
  
  // .headerButton {
  //   height: 18px;
  //   border-radius: 10.5px;
  //   padding: 0 11px;
  // }
  
  // .unpressedButton {
  //   font-size: 8px;
  // }
  
  // .pressedButton {
  //   font-size: 8px;
  // }
  .dayGrowthContainer {
    width: 76.5px!important;
  }
  .growDayRow {
  
    .content {
      width: 76.5px;
      height: 82.5px;
      &::-webkit-scrollbar {
        width: 5.5px; /* Толщина скроллбара */
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
      }
      
      .day {
        width: 13px;
        height: 13px;
        font-size: 12px;
        border-radius: 2px;
        margin: 2px;
      }
    }
  }
  
  .growDayChikenRow {
    padding: 1px 5.5px 1px 2px;
    border-radius: 5px;
    width: 66px;
    margin-left: 7px;
    margin-top: 3.5px;
    .input {
      font-size: 12px;
      max-width: 25.5px;
      width: 25.5px;
      height: 11.5px;
      border-radius: 4px;
      margin-right: 3.5px;
      padding: 1px;
    }
    .confirm {
      font-size: 12px;
    }
  }
}        