.container {
  display: flex;
  width: 22%;
  min-height: 132px;

  .yAxis {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    justify-content: space-between;
    min-height: 132px;

    .critical,
    .hi,
    .normal,
    .low {
      width: 12px;
      box-sizing: border-box;
      height: 100%;
    }
    .critical {
      background-color: #F24E1E;
      border-radius: 6px 6px 0 0;
    }
    .hi {
      background-color: #FFB23A;
    }

    .normal {
      background-color: #0ACF83;
    }

    .low {
      background-color: #0092E8;
      border-radius: 0 0 6px 6px;
    }
  }

  .tipNames {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    justify-content: space-between;


    .critical,
    .hi,
    .normal,
    .low {
      box-sizing: border-box;
      font-family: Roboto-Regular;
      font-size: 15px;
      color: #414141;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
    }

    .critical {

      span {
        color: #F24E1E;
        white-space: nowrap;
      }

    }

    .hi {

      span {
        color: #FFB23A;
        white-space: nowrap;
      }

    }

    .normal {

      span {
        color: #0ACF83;
        white-space: nowrap;

      }
    }

    .low {

      span {
        color: #0092E8;
        white-space: nowrap;

      }

    }
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    width: 17.5%;

    .yAxis {
      width: 8.5px;
      border-radius: 4px;
      gap: 3px;
      
      .critical,
      .hi,
      .normal,
      .low {
        width: 9.5px;
      }
      .critical {
        border-radius: 4px 4px 0 0;
      }

      .low {
        border-radius: 0 0 4px 4px;
      }
    }
  
    .tipNames {
      .critical,
      .hi,
      .normal,
      .low {
        font-size: 12px;
        padding-left: 8px;
      }
  
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    width: 15.5%;
  
    .yAxis {
      width: 6.5px;
      border-radius: 4px;
      gap: 3px;

      .critical,
      .hi,
      .normal,
      .low {
        width: 8.5px;
      }

      .critical {
        border-radius: 4px 4px 0 0;
      }

      .low {
        border-radius: 0 0 4px 4px;
      }
    }

    .tipNames {
      .critical,
      .hi,
      .normal,
      .low {
        font-size: 12px;
        padding-left: 7px;
      }
  
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    width: 12%;
  
    .yAxis {
      width: 12px;
      border-radius: 6px;
      gap: 5px;

      .critical,
      .hi,
      .normal,
      .low {
        width: 6.5px;
      }

      .critical {
        border-radius: 6px 6px 0 0;
      }

      .low {
        border-radius: 0 0 6px 6px;
      }
    }

    .tipNames {
      .critical,
      .hi,
      .normal,
      .low {
        font-size: 12px;
        padding-left: 5.5px;
      }
  
    }
  }
}