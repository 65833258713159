.select {
    border: 2px solid #414141;
    border-radius: 20px;
    padding: 0 58px 0 19px;
    height: 34px;
    position: relative;
    transition: .3s;
    position: relative;

    &.error {
       border-color: #FF0000!important;
    }

    .selectName {
        font-family: Roboto-Bold;
        cursor: pointer;
        white-space: nowrap;
        // max-width: 70px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        height: 30px;
        align-items: center;
        .downIcon {
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translateY(-50%);
        }
    }

    &.open {
        border-color: #fff;
        border-radius: 20px;
        background: #fff;
        .selectName {
            color: #C4C4C4;
            cursor: pointer;
            .downIcon {
                position: absolute;
                top: 50%;
                right: 19px;
                transform: translateY(-50%);
            }
        }
        .selectListRow {
            min-height: 168px;
            overflow: hidden;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            z-index: 10;
        }

        .selectList {
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100% + 18px);
            height: 100%;
            max-height: 133px;
            padding: 0 0 9px 19px;
            background: #fff;

        }
    }
}

.selectListRow {
    transition: .3s;
    max-height: 38px;
    height: 38px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    cursor: pointer;
    padding: 35px 0 0 0;

    .selectList {
        background: transparent;
        max-height: 0px;
        transition: .3s;
        overflow: hidden;
        padding: 0 0 0 19px;
    }
    .selectListItem {
        margin-bottom: 7px;
        font-size: 14px;
        word-break: break-all;
        cursor: pointer;
        padding-right: 5px;
        &:hover {
            color: #0acf83;
        }
    }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .select {
    border-radius: 16px;
    padding: 0 46.5px 0 15px;
    height: 27px;

    .selectName {
      // max-width: 56px;
      height: 24px;
      .downIcon {
        right: 15px;
      }
    }

    &.open {
      border-radius: 16px;
      .selectName {
        .downIcon {
          right: 15px;
        }
      }
      .selectListRow {
        min-height: 134.5px;
        border-radius: 16px;
      }

      .selectList {
        width: calc(100% + 14.5px);
        max-height: 106.5px;
        padding: 0 0 7px 15px;
      }
    }
  }

  .selectListRow {
    max-height: 30.5px;
    height: 30.5px;
    padding: 28px 0 0 0;

    .selectList {
      padding: 0 0 0 15px;
    }
    .selectListItem {
      margin-bottom: 5.5px;
      font-size: 12px;
      padding-right: 4px;
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .select {
    border: 1px solid #414141;
    border-radius: 14px;
    padding: 0 40.5px 0 13px;
    height: 24px;

    .selectName {
      // max-width: 49px;
      height: 21px;

      .downIcon {
        right: 13px;
      }
    }

    &.open {
      border-radius: 14px;
      .selectName {
        .downIcon {
          right: 13px;
        }
      }
      .selectListRow {
        min-height: 117.5px;
        border-radius: 14px;
      }

      .selectList {
        width: calc(100% + 12.5px);
        max-height: 93px;
        padding: 0 0 6px 13px;
      }
    }
  }

  .selectListRow {
    max-height: 26.5px;
    height: 26.5px;
    padding: 24.5px 0 0 0;

    .selectList {
      padding: 0 0 0 13px;
    }
    .selectListItem {
      margin-bottom: 5px;
      font-size: 12px;
      padding-right: 3.5px;
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .select {
    border-radius: 11px;
    padding: 0 32px 0 10.5px;
    height: 18.5px;

    .selectName {
      // max-width: 38.5px;
      height: 16.5px;

      .downIcon {
        right: 10.5px;
      }
    }

    &.open {
      border-radius: 11px;
      .selectName {
        .downIcon {
          right: 10.5px;
        }
      }
      .selectListRow {
        min-height: 92.5px;
        border-radius: 11px;
      }

      .selectList {
        width: calc(100% + 10px);
        max-height: 73px;
        padding: 0 0 5px 10.5px;
      }
    }
  }

  .selectListRow {
    max-height: 21px;
    height: 21px;
    padding: 19px 0 0 0;

    .selectList {
      padding: 0 0 0 10.5px;
    }
    .selectListItem {
      margin-bottom: 4px;
      font-size: 12px;
      padding-right: 3px;
    }
  } 
}