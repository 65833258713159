.container {
  display: flex;
  width: 100%;
  // height: 12rem;
  min-height: 132px;
  gap: 5px;
  direction: ltr;

  .chartContainer {
    flex-grow: 1;
  }
}

.spinnerContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: 1.1rem;
  }

}