.inputContent {
  &.error {
    input {
      border-color: #FF0000!important;
    }  
  }
  label {
    font-size: 15px;
  }
  input {
    margin-top: 5px;
    border: 1px solid #414141;
    border-radius: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 10px;
    position: relative;
    background: #fff;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .inputContent {
    label {
      font-size: 12px;
    }
    input {
      margin-top: 4px;
      border-radius: 12px;
      height: 24px;
      padding: 0px 16px 0px 8px;
    }
  }  
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .inputContent {
    label {
      font-size: 12px;
    }
    input {
      margin-top: 3.5px;
      border-radius: 12px;
      height: 21px;
      padding: 0px 14px 0px 7px;
    }
  }   
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .inputContent {
    label {
      font-size: 12px;
    }
    input {
      margin-top: 3px;
      border-radius: 8px;
      height: 16.5px;
      padding: 0px 11px 0px 5.5px;
    }
  }   
}