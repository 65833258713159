.secondBlock {
    display: flex;
    gap: 1.2rem;
    // padding-inline-end: .1rem;
    width: 100%;
    justify-content: space-between;
    .endBlock{
        display: flex;
        gap: 1.2rem;
    }
}
  
.todayButton {
    box-sizing: border-box;
    border: none;
    min-width: 5.06rem;
    padding-top: .44rem;
    padding-inline-end: 1.34rem;
    padding-bottom: .31rem;
    padding-inline-start: 1.34rem;
    color: #FFFFFF;
    font-family: Heebo-Bold;
    font-size: .88rem;
    line-height: 1.31rem;
    background-color: #0ACF83;
    border-radius: 1.19rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        filter: brightness(0.94);
        transition: filter .2s;
    }

    &:active {
        transform: translate(.06rem, .06rem);
    }

    &:disabled {
        filter: none;
        transform: none;
    }
}

.brightButton {
    background-color: #0ACF83;
}

.greyButton {
    background-color: #808080;
}
