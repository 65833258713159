.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 15px;
  
  .temperatureInformation {
    .logoTitle {
      svg {
        width: 27px;
        height: 42px;
        margin-right: 10px;
      }
    }  
  }
  .humidityInformation {
    .logoTitle {
      svg {
        width: 33px;
        height: 52px;
        margin-right: 4px;
      }
    } 
  }

  .temperatureInformation,
  .humidityInformation {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logoTitle {
      box-sizing: border-box;
      display: flex;
      align-items: center;

      span {
        font-family: Roboto-Bold;
        font-size: 18px;
        color: #414141;
      }
    }

    .informations {
      display: flex;
      justify-content: left;
      align-items: stretch;
      flex-grow: 1;
      padding-left: 37px;
      gap: 20px;
    }

    .informationsBySensor {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-grow: 1;
      padding-left: 37px;
      gap: 20px;
      .chart {
        min-width: 178px;
        min-height: 70px;
        max-height: 70px;
        // margin-left: -76px;
        // margin-left: -20px;
      }
    }

    .maxMinInformation {
      .maxInformation,
      .minInformation {
        display: flex;
        align-items: center;
        gap: 6px;

        .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #0ACF83;
        }

        .information {
          display: flex;
          align-items: center;
          
          gap: 5px;
          .time,
          .minMax {
            font-family: Roboto-Regular;
            font-size: 15px;
          }

          .index {
            font-family: Montserrat-Bold;
            font-size: 25px;
            color: #0ACF83;
          }
        }

        .percent {
          font-size: 15px;
        }
      }
    }

    .chart {
      min-width: 178px;
      // margin-left: -76px;
      // margin-left: -20px;
      flex: auto;
    }

    .actualInformation {
      box-sizing: border-box;
      // width: 16%;

      .now {
        font-family: Roboto-Regular;
        font-size: 15px;
      }

      .actualIndex {
        font-family: Montserrat-Bold;
        font-size: 30px;
        color: #0ACF83;
      }

      .percent {
        font-size: 30px;
      }
    }
  }
}

.componentContentBySensor {
  .lineChart {
    height: 220px;
  }

  .secondPart {
    .buttons {
        display: flex;
  
        button {
          height: 33px;
          border: 1px solid #0ACF83;
          border-radius: 19px;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          margin-right: 10px;
          svg {
            width: 12px;
            margin-right: 5px;
          }
        }
  
        .unpressedButton {
          background-color: #FFFFFF;
          color: #414141;
          font-family: Roboto-Regular;
          font-size: 15px;
        }
  
        .pressedButton {
          background-color: #0ACF83;
          font-family: Heebo-Bold;
          font-size: 15px;
          color: #FFFFFF;
        }
    }
  }
} 


@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    gap: 12px;
    
    .temperatureInformation {
      .logoTitle {
        svg {
          width: 21.5px;
          height: 33.5px;
          margin-right: 8px;
        }
      }  
    }
    .humidityInformation {
      .logoTitle {
        svg {
          width: 26.5px;
          height: 41.5px;
          margin-right: 3px;
        }
      } 
    }
  
    .temperatureInformation,
    .humidityInformation {
      .logoTitle {
        span {
          font-size: 15px;
        }
      }
  
      .informations {
        padding-left: 29.5px;
        gap: 16px;
      }

      .informationsBySensor {
        padding-left: 29.5px;
        gap: 20px;
        .chart {
          min-width: 157px;
          max-width: 157px;
          min-height: 56px;
          max-height: 56px;
          // margin-left: -76px;
          // margin-left: -20px;
        }
      }
  
      .maxMinInformation {
        .maxInformation,
        .minInformation {
          gap: 5px;
  
          .indicator {
            width: 6.5px;
            height: 6.5px;
          }
  
          .information {
            gap: 4px;
            .time,
            .minMax {
              font-size: 12px;
            }
  
            .index {
              font-size: 20px;
            }
          }
  
          .percent {
            font-size: 12px;
          }
        }
      }
  
      .chart {
        min-width: 157px;
        // margin-left: -76px;
        // margin-left: -20px;

      }
  
      .actualInformation {
        .now {
          font-size: 12px;
        }
  
        .actualIndex {
          font-size: 24px;
        }
  
        .percent {
          font-size: 24px;
        }
      }
    }
  }

  .componentContentBySensor {
    .lineChart {
      height: 176px;
    }
  
    .secondPart {
      .buttons {
          button {
            height: 26.5px;
            border-radius: 15px;
            padding: 0 16px;
            margin-right: 8px;
            svg {
              width: 9.5px;
              margin-right: 4px;
            }
          }
    
          .unpressedButton {
            font-size: 12px;
          }
    
          .pressedButton {
            font-size: 12px;
          }
      }
    }
  } 
}
  
@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    gap: 10.5px;
    
    .temperatureInformation {
      .logoTitle {
        svg {
          width: 19px;
          height: 29.5px;
          margin-right: 7px;
        }
      }  
    }
    .humidityInformation {
      .logoTitle {
        svg {
          width: 23px;
          height: 36.5px;
          margin-right: 3px;
        }
      } 
    }
  
    .temperatureInformation,
    .humidityInformation {
      .logoTitle {
        span {
          font-size: 13px;
        }
      }
  
      .informations {
        padding-left: 26px;
        gap: 14px;
      }

      .informationsBySensor {
        // padding-left: 26px;
        gap: 14px;
        .chart {
          min-width: 135px;
          max-width: 135px;
          min-height: 49px;
          max-height: 49px;
          // margin-left: -76px;
          // margin-left: -20px;
        }
      }
  
      .maxMinInformation {
        .maxInformation,
        .minInformation {
          gap: 4px;
  
          .indicator {
            width: 5.5px;
            height: 5.5px;
          }
  
          .information {
            gap: 3.5px;
            .time,
            .minMax {
              font-size: 12px;
            }
  
            .index {
              font-size: 17.5px;
            }
          }
  
          .percent {
            font-size: 12px;
          }
        }
      }
  
      .chart {
        min-width: 147.5px;
        // margin-left: -76px;
        // margin-left: -20px;
      }
  
      .actualInformation {
        .now {
          font-size: 12px;
        }
  
        .actualIndex {
          font-size: 21px;
        }
  
        .percent {
          font-size: 21px;
        }
      }
    }
  }

  .componentContentBySensor {
    .lineChart {
      height: 154px;
    }
  
    .secondPart {
      .buttons {
          button {
            height: 23px;
            border-radius: 13.5px;
            padding: 0 14px;
            margin-right: 7px;
            svg {
              width: 8.5px;
              margin-right: 3.5px;
            }
          }
    
          .unpressedButton {
            font-size: 12px;
          }
    
          .pressedButton {
            font-size: 12px;
          }
      }
    }
  } 
}
  
@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    gap: 8px;
    
    .temperatureInformation {
      .logoTitle {
        svg {
          width: 15px;
          height: 23px;
          margin-right: 5.5px;
        }
      }  
    }
    .humidityInformation {
      .logoTitle {
        svg {
          width: 18px;
          height: 28.5px;
          margin-right: 2px;
        }
      } 
    }
  
    .temperatureInformation,
    .humidityInformation {
      .logoTitle {
        span {
          font-size: 12px;
        }
      }
  
      .informations {
        padding-left: 20px;
        gap: 11px;
      }

      .informationsBySensor {
        gap: 11px;
        .chart {
          min-width: 128px;
          max-width: 128px;
          min-height: 38.5px;
          max-height: 38.5px;
          // margin-left: -76px;
          // margin-left: -20px;
        }
      }
  
      .maxMinInformation {
        .maxInformation,
        .minInformation {
          gap: 3px;
  
          .indicator {
            width: 4.5px;
            height: 4.5px;
          }
  
          .information {
            gap: 2.5px;
            .time,
            .minMax {
              font-size: 12px;
            }
  
            .index {
              font-size: 14px;
            }
          }
  
          .percent {
            font-size: 12px;
          }
        }
      }
  
      .chart {
        min-width: 132px;
        // margin-left: -76px;
        // margin-left: -20px;
      }
  
      .actualInformation {
        .now {
          font-size: 12px;
        }
  
        .actualIndex {
          font-size: 16.5px;
        }
  
        .percent {
          font-size: 16.5px;
        }
      }
    }
  }

  .componentContentBySensor {
    .lineChart {
      height: 121px;
    }
  
    .secondPart {
      .buttons {
          button {
            height: 18px;
            border-radius: 10.5px;
            padding: 0 11px;
            margin-right: 5.5px;
            svg {
              width: 6.6px;
              margin-right: 3px;
            }
          }
    
          .unpressedButton {
            font-size: 12px;
          }
    
          .pressedButton {
            font-size: 12px;
          }
      }
    }
  } 
}            