.commonButton {
  padding: .44rem 1.13rem;
  border: none;
  border-radius: 1.19rem;
  font-family: Roboto-Bold;
  font-size: .94rem;
  color: #FFFFFF;
  cursor: pointer;


  &:hover {
    filter: brightness(0.94);
    transition: filter .2s;
  }

  &:active {
    transform: translate(.06rem, .06rem);
  }
}