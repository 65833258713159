.notifRow {
    position: fixed;
    bottom: 5px;
    right: 20px;
    width: 300px;
    .notifItem {
        width: 100%;
        margin: 10px 0;
        border-radius: 4px;
        padding: 5px;
        &.error {
            border: 2px solid rgb(162 5 5);
            background: rgb(203 52 52);
            color: #3f3131;
            padding: 5px;
            box-shadow: 0 0 5px 1px rgb(162 5 5);
        }
        &.info {
            border: 2px solid rgb(52, 132, 30);
            background: rgb(176, 233, 160);
            color: #3f3131;
            padding: 5px;
            box-shadow: 0 0 5px 1px rgb(52, 132, 30);
        }
    }
    
}