.container {

  background: #fff;
  // position: relative;
}

.content {

  // position: relative;
  &>.row {
    padding-top: 25px;
    padding-left: 60px;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 1824px) {  // 95% screen 80% from base size if 100% is 1920px screen
  .content {
    &>.row {
      padding-top: 20px;
      padding-left: 48px;
    }
  }
}

@media (max-width: 1536px) {  // 80% screen 70% from base size if 100% is 1920px screen
  .content {
    &>.row {
      padding-top: 17.5px;
      padding-left: 42px;
    }
  }
}

@media (max-width: 1344px) {  // 70% screen 55% from base size if 100% is 1920px screen
  .content {
    &>.row {
      padding-top: 13.5px;
      padding-left: 33px;
    }
  }
}