.container {
  box-sizing: border-box;
  padding-inline-start: 1.44rem;
  padding-inline-end: 1.44rem;
  padding-bottom: 1.81rem;
  width: 100%;
  max-width: 120rem;
  min-width: 73.9rem;
  margin-left: auto;
  margin-right: auto;

  .columnsContainer {
    display: flex;
    width: 100%;

    .firstColumn {
      // width: 25.88%;
      width: 71%;
      margin-right: 20px;
    }

    .secondColumn {
      // width: 72.9%;
      // max-width: 518px;
      width: 29%;
      // width: 100%;

      .deathGrowthRates {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        gap: 1.06rem;
      }
    }

    .lineColumn {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }

  }
}