.container {
  position: relative;
  width: 26rem;
  padding-top: 3.55rem;
  padding-inline-end: 2rem;
  padding-bottom: 3.38rem;
  padding-inline-start: 2rem;
  background-color: #FFFFFF;

  p {
    text-align: center;
    font-family: Montserrat-Bold;
    font-size: 1.26rem;
    line-height: 1.58rem;
    color: #414141;
    letter-spacing: .047rem;
    margin-bottom: 1.31rem;
  }

  .buttonsStack {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
}