.container {
  position: relative;
  width: 26rem;
  height: 29.06rem;
  padding-top: 2.75rem;
  padding-inline-end: 4.83rem;
  padding-bottom: 3.38rem;
  padding-inline-start: 4.19rem;
  background-color: #FFFFFF;

  h2 {
    font-family: Montserrat-Bold;
    font-size: 1.56rem;
    line-height: 1.88rem;
    color: #414141;
    letter-spacing: .047rem;
    margin-bottom: 1.31rem;
  }

  .durationStack {
    display: flex;
    justify-content: space-between;
  }

  .durationStack {
    width: 100%;

    label>input {
      max-width: 7.63rem;
      margin-bottom: 3.7rem;
    }
  }

  input {
    margin-bottom: 2rem;
  }
}