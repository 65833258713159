.inputLabel {
  display: flex;
  flex-direction: column;

  .inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  span {
    font-family: Roboto-Bold;
    font-size: .94rem;
    line-height: 1.13rem;
    color: #414141;
    margin-bottom: .43rem;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    height: 1.88rem;
    padding-inline-start: .75rem;
    outline: #414141 solid .06rem;
    border-radius: 1.19rem;
    border: none;
    font-family: Roboto-Regular;
    font-size: .93rem;

    &:focus {
      outline: #414141 solid .13rem;
    }

    &:hover {
      outline: #414141 solid .13rem;
    }
  }

  img {
    cursor: pointer;
    filter: opacity(0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    width: 1.4rem;
    height: 1.3rem;
  }

  .iconLTR {
    left: .5rem;
  }

  .iconRTL {
    right: .5rem;
  }

  .errorMessage {
    position: absolute;
    color: red;
    font-family: Roboto-Regular;
    font-size: 0.74rem;
    padding-inline-start: .2rem;
    bottom: -1rem;
  }
}