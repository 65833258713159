.container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  font-family: Roboto-Regular;
  
  .componentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-family: Roboto-Bold;
      font-size: 25px;
      color: #414141;
    }

    .headerButtons {
      display: flex;

      .growDay {
        font-size: 15px;
        display: flex;
        align-items: center;
        label {
          margin-right: 5px;
        }
      }
    }
  }
  .chart  {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .mainContent {
    flex-grow: 1;
    display: flex;

    .graphSide {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .graph {
        flex-grow: 1;
        width: 100%;
        overflow-x: hidden;
        margin-right: 15px;
        // display: flex;
        // align-items: center;
        // justify-content: center;

        // &>div {
        //   width: 105%;
        //   height: 100%;
        // }
      }

      .legend {
        display: flex;
        justify-content: end;
        // flex-direction: column;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          margin-right: 10px;
        }
  
        .blueCircle,
        .greenCircle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
            margin-right: 5px;
        }
  
        .blueCircle {
          background-color: #425BFE;
        }
  
        .greenCircle {
          background-color: #0ACF83;
        }
  
        span {
          font-family: Roboto-Regular;
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    border-radius: 8px;
    padding: 16px;
    
    .componentHeader {
      margin-bottom: 16px;

      h3 {
        font-size: 20px;
      }

      .headerButtons {
        .growDay {
          font-size: 12px;
          label {
            margin-right: 4px;
          }
        }
      }
    }

    .mainContent {
      .graphSide {
        .graph {
          margin-right: 12px;
        }

        .legend {
          .item {
            margin-bottom: 12px;
          }
    
          .blueCircle,
          .greenCircle {
            width: 9px;
            height: 9px;
            margin-right: 4px;
          }
    
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
  
@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    border-radius: 7px;
    padding: 14px;
    
    .componentHeader {
      margin-bottom: 14px;

      h3 {
        font-size: 17.5px;
      }

      .headerButtons {
        .growDay {
          font-size: 12px;
          label {
            margin-right: 3.5px;
          }
        }
      }
    }

    .mainContent {
      .graphSide {
        .graph {
          margin-right: 10.5px;
        }

        .legend {
          .item {
            margin-bottom: 10.5px;
          }
    
          .blueCircle,
          .greenCircle {
            width: 8px;
            height: 8px;
            margin-right: 3.5px;
          }
    
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
  
@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    border-radius: 5.5px;
    padding: 11px;
    
    .componentHeader {
      margin-bottom: 11px;

      h3 {
        font-size: 14px;
      }

      .headerButtons {
        .growDay {
          font-size: 12px;
          label {
            margin-right: 3px;
          }
        }
      }
    }

    .mainContent {
      .graphSide {
        .graph {
          margin-right: 8px;
        }

        .legend {
          .item {
            margin-bottom: 8px;
          }
    
          .blueCircle,
          .greenCircle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 3px;
          }
    
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}            