.secondBlock {
    display: flex;
    gap: 1.2rem;
    // padding-inline-end: .1rem;
    width: 100%;
    justify-content: space-between;
    .endBlock{
        display: flex;
        gap: 1.2rem;
    }
}