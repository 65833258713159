.container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
  font-family: Roboto-Regular;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);

  .componentHeader {
    display: flex;
    // margin-bottom: 20px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    .headerBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
    }

    .componentHeaderBlock {
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      
    }

    .sortFilterHeaderBlock {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      font-family: Roboto-Regular;
      .sortBlock {
        display: flex;
        align-items: center;
        .checkbox {
          margin-right: 10px;
        }
        label {
          margin-right: 10px;
        }
        margin-bottom: 10px;
      }
      .reloadIcon {
        margin-left: 10px;
        cursor: pointer;
      }

      .statusBlock {
        height: 34px;
        margin-right: 8px;
        font-family: Roboto-Regular;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border: 1px solid #000;
        border-radius: 50px;
        svg {
          margin-right: 5px;
          max-width: 16px;
          max-height: 16px;
        }
      }
    }

    .buttonFilterPriority {
      height: 34px;
      margin-right: 8px;
      font-family: Roboto-Regular;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border: 1px solid #000;
      border-radius: 50px;
      
      &.high {
        color: #992300;
        border-color: #992300;
        &:hover {
          background-color: #992300;
          color: #fff;
        }
      }

      &.medium {
        color: #FF3A00;
        border-color: #FF3A00;
        &:hover {
          background-color: #FF3A00;
          color: #fff;
        }
      }

      &.low {
        color: #FFB23A;
        border-color: #FFB23A;
        &:hover {
          background-color: #FFB23A;
          color: #fff;
        }
      }
    }

    h3 {
      font-family: Roboto-Bold;
      font-size: 25px;
      color: #414141;
      margin-right: 20px;
    }
  }

  .messageRow {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .messageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 15px);
    // overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: -15px;

    .containerAlerts {
      .header {
        background: #992300;
        border-radius: 0 0 40px 0;
        height: 52px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        color: #fff;
        font-size: 20px;
        font-family: Roboto-Bold;
        margin-bottom: 18px;
      }
      &.high {
        .header {
          background: #992300;
        }
      }

      &.medium {
        .header {
          background: #FF3A00;
        }
      }

      &.low {
        .header {
          background: #FFB23A;
        }
      }
    }
  }

  .messageContainerRtl {
    direction: ltr;
  }
}

.spinnerContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    border-radius: 8px 8px 0px 0px;
    padding: 16px;
    font-size: 12px;
  
    .componentHeader {
      .headerBlock {
        margin-bottom: 12px;
      }
  
      .componentHeaderBlock {
        margin-bottom: 12px;
      }
  
      .sortFilterHeaderBlock {
        margin-bottom: 12px;
        .sortBlock {
          .checkbox {
            margin-right: 8px;
          }
          label {
            margin-right: 8px;
          }
          margin-bottom: 8px;
        }
        .reloadIcon {
          margin-left: 8px;
        }
  
        .statusBlock {
          height: 27px;
          margin-right: 6.5px;
          padding: 0 8px;
          border-radius: 40px;
          svg {
            margin-right: 4px;
            max-width: 13px;
            max-height: 13px;
          }
        }
      }
  
      .buttonFilterPriority {
        height: 27px;
        margin-right: 6.5px;
        padding: 0 8px;
        border-radius: 40px;
      }
  
      h3 {
        font-size: 20px;
        margin-right: 16px;
      }
    }
    .messageRow {
      margin-left: -16px;
      margin-right: -16px;
    }

    .messageContainer {
      
      .containerAlerts {
        
        .header {
          border-radius: 0 0 32px 0;
          height: 41.5px;
          padding-left: 16px;
          font-size: 16px;
          margin-bottom: 14.5px;
        }
  
      }
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    border-radius: 7px 7px 0px 0px;
    padding: 14px;
  
    .componentHeader {
      .headerBlock {
        margin-bottom: 10.5px;
      }
  
      .componentHeaderBlock {
        margin-bottom: 10.5px;
      }
  
      .sortFilterHeaderBlock {
        margin-bottom: 10.5px;
        .sortBlock {
          .checkbox {
            margin-right: 7px;
          }
          label {
            margin-right: 7px;
          }
          margin-bottom: 7px;
        }
        .reloadIcon {
          margin-left: 7px;
        }
  
        .statusBlock {
          height: 24;
          margin-right: 5.5px;
          padding: 0 7px;
          border-radius: 35px;
          svg {
            margin-right: 3.5px;
            max-width: 11px;
            max-height: 11px;
          }
        }
      }
  
      .buttonFilterPriority {
        height: 24px;
        margin-right: 5.5px;
        padding: 0 7px;
        border-radius: 34px;
      }
  
      h3 {
        font-size: 17.5px;
        margin-right: 14px;
      }
    }

    .messageRow {
      
      margin-left: -14px;
      margin-right: -14px;
    }
  
    .messageContainer {
      .containerAlerts {
        
        .header {
          border-radius: 0 0 28px 0;
          height: 36.5px;
          padding-left: 14px;
          font-size: 14px;
          margin-bottom: 12.5px;
        }
  
      }
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    border-radius: 5.5px 5.5px 0px 0px;
    padding: 11px;
  
    .componentHeader {
      .headerBlock {
        margin-bottom: 8px;
      }
  
      .componentHeaderBlock {
        margin-bottom: 8px;
      }
  
      .sortFilterHeaderBlock {
        margin-bottom: 8px;
        .sortBlock {
          .checkbox {
            margin-right: 5.5px;
          }
          label {
            margin-right: 5.5px;
          }
          margin-bottom: 5.5px;
        }
        .reloadIcon {
          margin-left: 5.5px;
        }
  
        .statusBlock {
          height: 19px;
          margin-right: 4.5px;
          padding: 0 5.5px;
          border-radius: 27.5px;
          svg {
            margin-right: 3px;
            max-width: 9px;
            max-height: 9px;
          }
        }
      }
  
      .buttonFilterPriority {
        height: 19px;
        margin-right: 4.5px;
        padding: 0 5.5px;
        border-radius: 27.5px;
      }
  
      h3 {
        font-size: 14px;
        margin-right: 11px;
      }
    }
  
    .messageRow {
      
      margin-left: -11px;
      margin-right: -11px;
    }

    .messageContainer {
      
      .containerAlerts {
        
        .header {
          border-radius: 0 0 22px 0;
          height: 28.5px;
          padding-left: 11px;
          font-size: 11px;
          margin-bottom: 10px;
        }
  
      }
    }
  }
}    