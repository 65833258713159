.container {
  box-sizing: border-box;
  padding-inline-start: 1.44rem;
  padding-inline-end: 1.44rem;
  padding-bottom: 1.81rem;
  width: 100%;
  max-width: 120rem;
  min-width: 73.9rem;
  margin-left: auto;
  margin-right: auto;

  .columnsContainer {
    display: flex;
    width: 100%;
  }
}