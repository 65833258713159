.container {
  background: #2f2f2f;
  border-radius: 8px;
  padding: 10px 13px;
  display: flex;
  font-family: Roboto-Regular;
  align-items: stretch;

  .label {
    width: 40%;
    margin-right: 15px;
    color: #fff;
    .name{
      font-size: 20px;
      font-family: Roboto-Bold;
      margin-bottom: 5px;
    }
    .info {
      color: #0092E8;
      font-size: 15px;
      font-family: Roboto-Bold;
    }
  }
  .data {
    color: #fff;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .id {
      opacity: .6;
      margin-bottom: 15px;
    }
    .status {
      .val {
        font-family: Roboto-Bold;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          width: 11px;
          height: 11px;
          border-radius: 50%;
          margin-right: 5px;
          display: block;
        }
        &.sensorOn {
          &:before {
            background: #0ACF83;
          }
        }
        &.sensorOff {
          &:before {
            background: #9BA7A2;
          }
        }
        &.sensorError {
          &:before {
            background: #FF3A00;
          }
        }
      }
    }
  }
}


@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    border-radius: 10.5px;
    padding: 8px 13px;
  
    .label {
      margin-right: 12px;
      .name{
        font-size: 16px;
        margin-bottom: 4px;
      }
      .info {
        font-size: 12px;
      }
    }
    .data {
      font-size: 12px;
  
      .id {
        margin-bottom: 12px;
      }
      .status {
        .val {
          &:before {
            content: '';
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }  
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    border-radius: 5.5px;
    padding: 7px 9px;
  
    .label {
      margin-right: 10.5px;
      .name{
        font-size: 14px;
        margin-bottom: 3.5px;
      }
      .info {
        font-size: 12px;
      }
    }
    .data {
      font-size: 12px;
  
      .id {
        margin-bottom: 10.5px;
      }
      .status {
        .val {
          &:before {
            content: '';
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }  
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    border-radius: 4.5px;
    padding: 5.5px 7.5px;
  
    .label {
      margin-right: 8px;
      .name{
        font-size: 12px;
        margin-bottom: 3px;
      }
      .info {
        font-size: 12px;
      }
    }
    .data {
      font-size: 12px;
  
      .id {
        margin-bottom: 8px;
      }
      .status {
        .val {
          &:before {
            content: '';
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }  
}