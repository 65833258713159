.container {
  background-color: #FFFFFF;
  box-sizing: border-box;
  width: 50%;
  display: flex;
  flex-direction: column;

  .sensorHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-top: 20px;
    align-items: center;

    h2 {
      font-family: Montserrat-Bold;
      font-size: 25px;
      margin: 0;
      color: #414141;
    }

    .buttons {
      .editButton {
        cursor: pointer;
        width: 17px;
        height: 17px;
        &::after {
          content: "";
          border-bottom: 1px solid transparent;
          width: 100%;
          display: block;
          margin-top: -3px;
        }
        &:hover:after {
          border-bottom: 1px solid #414141;
          // border-radius: 50%;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .sensorPads {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    .sensorPadContainer {
      align-items: stretch;
    }
  }
}

.spinnerContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
 
    .sensorHeader {
      margin-bottom: 12px;
      padding-top: 16px;
  
      h2 {
        font-size: 20px;
      }

      .buttons {
        .editButton {
          cursor: pointer;
          width: 13.5px;
          height: 13.5px;
        }
      }
    }
  
    .sensorPads {
      column-gap: 6.5px;
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
 
    .sensorHeader {
      margin-bottom: 10.5px;
      padding-top: 14px;
  
      h2 {
        font-size: 17.5px;
      }
      .buttons {
        .editButton {
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
      }
    }
  
    .sensorPads {
      column-gap: 5.5px;
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
 
    .sensorHeader {
      margin-bottom: 8px;
      padding-top: 11px;
  
      h2 {
        font-size: 14px;
      }
      .buttons {
        .editButton {
          cursor: pointer;
          width: 9px;
          height: 9px;
        }
      }
    }
  
    .sensorPads {
      column-gap: 4.5px;
    }
  }
}