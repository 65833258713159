.container {
  box-sizing: border-box;
  display: flex;
  width: 50%;

  .efficiency {
    box-sizing: border-box;
    min-width: 161px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    background: #0ACF83;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &.green {
      background: #0ACF83;
    }
    &.yellow {
      background: #ecb555;
    }
    &.orange {
      background: #e04d27;
    }
    &.red {
      background: #862d14;
    }

    h3 {
      font-family: Roboto-Bold;
      font-size: 25px;
    }
    .icon {
      width: 51px;
      height: 51px;
      margin: 14px 0;
    }
    .value {
      font-family: Montserrat-Bold;
      font-size: 40px;
    }
  }

  .PHIndexBar {
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .headerWithButton {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      h3 {
        font-family: Roboto-Bold;
        font-size: 25px;
        color: #414141;
      }

      .buttons {
        display: flex;

        button {
          height: 33px;
          border: 1px solid #0ACF83;
          border-radius: 19px;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
        }

        .pressed {
          background-color: #0ACF83;
          font-family: Heebo-Bold;
          font-size: 15px;
          color: #FFFFFF;
        }

        .unpressed {
          background-color: #FFFFFF;
          color: #414141;
          font-family: Roboto-Regular;
          font-size: 15px;
        }

        .averageButton {
          margin-inline-start: .4rem;
        }
      }
    }

    .bar {
      height: 24px;
      width: 100%;
      align-items: center;
      position: relative;
      justify-content: center;
      display: flex;
      margin-bottom: 12px;

      .barLine {
        background: transparent linear-gradient(90deg, #0ACF8324 0%, #0ACF83FC 100%) 0% 0% no-repeat padding-box;
        border-radius: 17px;
        width: 100%;
        height: 12px;
      }

      .poorText {
        position: absolute;
        font-size: 15px;
        top: 100%;
        left: 0;
        font-family: Roboto-Regular;
      } 
      .goodText {
        position: absolute;
        font-size: 15px;
        top: 100%;
        right: 0;
        font-family: Roboto-Regular;
      }

      .firstCase {
        border-radius: 1.06rem 0 0 1.06rem;
      }

      .secondCase {
        border-radius: 0 1.06rem 1.06rem 0;
      }

      .pointer {
        position: absolute;
        top: 0;
        height: 100%;
        width: .25rem;
        background-color: #414141;
        border-radius: .13rem;
      }
    }
  }
}

.containerForSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    .efficiency {
      min-width: 129px;
      border-radius: 5px;
      margin-right: 8px;
  
      h3 {
        font-size: 20px;
      }
      .icon {
        width: 42px;
        height: 42px;
        margin: 11px 0;
      }
      .value {
        font-size: 32px;
      }
    }
  
    .PHIndexBar {
      width: 100%;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 8px;
      padding: 16px;
  
      .headerWithButton {
        width: 100%;
        margin-bottom: 32px;
  
        h3 {
          font-size: 20px;
        }
  
        .buttons {
  
          button {
            height: 26px;
            border-radius: 15px;
            padding: 0 16px;
          }
  
          .pressed {
            font-size: 12px;
          }
  
          .unpressed {
            font-size: 12px;
          }
  
          .averageButton {
            margin-inline-start: .4rem;
          }
        }
      }
  
      .bar {
        height: 19px;
        width: 100%;
  
        .barLine {
          border-radius: 13px;
          width: 100%;
          height: 9px;
        }
  
        .poorText {
          font-size: 12px;
          top: 100%;
          left: 0;
        } 
        .goodText {
          font-size: 12px;
          top: 100%;
          right: 0;
        }
        
        .firstCase {
          border-radius: 1.06rem 0 0 1.06rem;
        }
  
        .secondCase {
          border-radius: 0 1.06rem 1.06rem 0;
        }
  
        .pointer {
          top: 0;
          height: 100%;
          width: .25rem;
          border-radius: .13rem;
        }
      }
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    .efficiency {
      min-width: 113px;
      border-radius: 4px;
      margin-right: 7px;
  
      h3 {
        font-size: 17.5px;
      }
      .icon {
        width: 39px;
        height: 39px;
        margin: 10px 0;
      }
      .value {
        font-size: 28px;
      }
    }
  
    .PHIndexBar {
      box-sizing: border-box;
      width: 100%;
      border-radius: 7px;
      padding: 14px;
  
      .headerWithButton {
        width: 100%;
        margin-bottom: 28px;
  
        h3 {
          font-size: 17.5px;
        }
  
        .buttons {
          display: flex;
  
          button {
            height: 23px;
            border-radius: 13px;
            padding: 0 14px;
          }
  
          .pressed {
            font-size: 12px;
          }
  
          .unpressed {
            font-size: 12px;
          }
  
          .averageButton {
            margin-inline-start: .4rem;
          }
        }
      }
  
      .bar {
        height: 17px;
        width: 100%;
  
        .barLine {
          border-radius: 12px;
          width: 100%;
          height: 8.5px;
        }
  
        .poorText {
          font-size: 12px;
          top: 100%;
          left: 0;
        } 
        .goodText {
          font-size: 12px;
          top: 100%;
          right: 0;
        }
  
        .firstCase {
          border-radius: 1.06rem 0 0 1.06rem;
        }
  
        .secondCase {
          border-radius: 0 1.06rem 1.06rem 0;
        }
  
        .pointer {
          top: 0;
          height: 100%;
          width: .25rem;
          border-radius: .13rem;
        }
      }
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    .efficiency {
      min-width: 88.5px;
      border-radius: 3px;
      margin-right: 5.5px;

      h3 {
        font-size: 14px;
      }
      .icon {
        width: 32.5px;
        height: 32.5px;
        margin: 8px 0;
      }
      .value {
        font-family: Montserrat-Bold;
        font-size: 22px;
      }
    }

    .PHIndexBar {
      width: 100%;
      border-radius: 5.5px;
      padding: 11px;

      .headerWithButton {
        width: 100%;
        margin-bottom: 22px;

        h3 {
          font-family: Roboto-Bold;
          font-size: 14px;
          color: #414141;
        }

        .buttons {

          button {
            height: 18px;
            border-radius: 10.5px;
            padding: 0 11px;
          }

          .pressed {
            font-size: 12px;
          }

          .unpressed {
            font-size: 12px;
          }

          .averageButton {
            margin-inline-start: .4rem;
          }
        }
      }

      .bar {
        height: 13px;
        width: 100%;

        .barLine {
          border-radius: 9px;
          width: 100%;
          height: 6.5px;
        }

        .poorText {
          font-size: 12px;
          top: 100%;
          left: 0;
        } 
        .goodText {
          font-size: 12px;
          top: 100%;
          right: 0;
        }

        .firstCase {
          border-radius: 1.06rem 0 0 1.06rem;
        }

        .secondCase {
          border-radius: 0 1.06rem 1.06rem 0;
        }

        .pointer {
          top: 0;
          height: 100%;
          width: .25rem;
          border-radius: .13rem;
        }
      }
    }
  }
}