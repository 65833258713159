.input {
    position: relative;
    width: 22px;
    height: 22px;
    label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    
    input + label {
        border-radius: 4px;
        border: 2px solid #231F20;
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
    }

    input:checked + label {
        &:before {
            background: #231F20;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4KICA8ZyBpZD0iR3JvdXBfMTU0NiIgZGF0YS1uYW1lPSJHcm91cCAxNTQ2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDA2IC0xNjIpIj4KICAgIDxnIGlkPSJDb21wb25lbnRfNDlfNiIgZGF0YS1uYW1lPSJDb21wb25lbnQgNDkg4oCTIDYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwNiAxNjIpIj4KICAgICAgPGcgaWQ9IlJlY3RhbmdsZV80NzUiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQ3NSIgZmlsbD0iIzIzMWYyMCIgc3Ryb2tlPSIjMjMxZjIwIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICAgIDxyZWN0IHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgcng9IjQiIHN0cm9rZT0ibm9uZSIvPgogICAgICAgIDxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHJ4PSIzLjUiIGZpbGw9Im5vbmUiLz4KICAgICAgPC9nPgogICAgICA8cGF0aCBpZD0iUGF0aF8xNjIzIiBkYXRhLW5hbWU9IlBhdGggMTYyMyIgZD0iTTM4NTkuNTIzLDU2My41ODVsNS4zNzYsNS40NjEsOS43NTgtOS43NTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zODU1LjI1NSAtNTU0KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIuNSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==);
        }
    }

    input {
        position: absolute;
        opacity: 0;
    }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
    .input {
        width: 17.5px;
        height: 17.5px;
        
        input + label {
            border-radius: 3px;
            border: 1px solid #231F20;
            width: calc(100% - 1px);
            height: calc(100% - 1px);
        }
    }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
    .input {
        width: 15.5px;
        height: 15.5px;
        
        input + label {
            border-radius: 3px;
        }
    }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
    .input {
        width: 12px;
        height: 12px;
        
        input + label {
            border-radius: 2px;
        }
    }
}            