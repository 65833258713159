.container {
  box-sizing: border-box;
  padding-inline-start: 1.44rem;
  padding-inline-end: 1.44rem;
  padding-bottom: 1.81rem;
  width: 100%;
  max-width: 120rem;
  min-width: 73.9rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 25px;

  .containerItem {
    flex-grow: 1;
    
    .label {
      font-family: 'Roboto-Bold', sans-serif;
      font-size: 18px;
      align-items: flex-end;
      display: flex;
      margin-bottom: 33px;
      svg {
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
    }

    .tableBlock {
      box-shadow: 0px 10px 20px #00000029;
      border-radius: 10px;
      display: table;
      width: 100%;
      padding: 0 10px;
      .tableHeader {
        font-size: 15px;
        font-family: 'Roboto-Bold', sans-serif;
        display: flex;
        display: table-row;
        .item {
          padding-top:20px;
          padding-right: 20px;
          padding-bottom: 17px;
          display: table-cell;
          text-decoration: underline;
        }
        .item:first-child{
          padding-left: 48px;
        }

        .item:last-child{
          padding-right: 48px;
        }
      }

      .tableBody {
        display: table-row-group;
        .itemRow {
          display: table-row;
          cursor: pointer;
          &.hover {
            .item {
              background: #000000;
              color: #fff;
            }
            .item:first-child{
              border-radius: 20px 0 0 20px;
            }
            .item:last-child{
              border-radius: 0 20px 20px 0;
            }
          }
          .item {
            display: table-cell;
            font-size: 20px;
            &>div {
              display: flex;
              height: 71px;
              align-items: center;
            }

            .border {
              height: 40px;
              border: 2px solid #C5C5C5;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 21px;
              padding: 0 26px;
              &.high {
                background: #A42500;
                border-color: #A42500;
                color: #fff;
                font-family: 'Roboto-Bold', sans-serif;
              }
              &.medium {
                background: #F24E1E;
                border-color: #F24E1E;
                color: #fff;
                font-family: 'Roboto-Bold', sans-serif;
              }
              &.low {
                background: #FFB23A;
                border-color: #FFB23A;
                color: #fff;
                font-family: 'Roboto-Bold', sans-serif;
              }
              &.total {
                box-shadow: inset 0px 3px 6px #00000029;
              } 
              &.up {
                border-color: #0ACF83;
              }
              &.down {
                border-color: #F24E1E;
              }
            }
          }
          .item:first-child{
            padding-left: 45px;
          }
          .item:last-child{
            padding-right: 45px;
          }
        }
      }
    }
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    gap: 20px;
  
    .containerItem {
      .label {
        font-size: 14.5px;
        margin-bottom: 26.5px;
        svg {
          width: 22.5px;
          height: 22.5px;
          margin-right: 9.5px;
        }
      }
  
      .tableBlock {
        border-radius: 8px;
        padding: 0 8px;
        .tableHeader {
          font-size: 12px;
          .item {
            padding-top:16px;
            padding-right: 16px;
            padding-bottom: 13.5px;
          }
          .item:first-child{
            padding-left: 38.5px;
          }
  
          .item:last-child{
            padding-right: 38.5px;
          }
        }
  
        .tableBody {
          .itemRow {
            &.hover {
              .item:first-child{
                border-radius: 16px 0 0 16px;
              }
              .item:last-child{
                border-radius: 0 16px 16px 0;
              }
            }
            .item {
              font-size: 16px;
              &>div {
                height: 57px;
              }
  
              .border {
                height: 32px;
                border: 2px solid #C5C5C5;
                border-radius: 17px;
                padding: 0 21px;
              }
            }
            .item:first-child{
              padding-left: 36px;
            }
            .item:last-child{
              padding-right: 36px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    gap: 17.5px;
  
    .containerItem {
      .label {
        font-size: 12.5px;
        margin-bottom: 23px;
        svg {
          width: 19.5px;
          height: 19.5px;
          margin-right: 8.5px;
        }
      }
  
      .tableBlock {
        border-radius: 7px;
        padding: 0 7px;
        .tableHeader {
          font-size: 12px;
          .item {
            padding-top:14px;
            padding-right: 14px;
            padding-bottom: 12px;
          }
          .item:first-child{
            padding-left: 33.5px;
          }
  
          .item:last-child{
            padding-right: 33.5px;
          }
        }
  
        .tableBody {
          .itemRow {
            &.hover {
              .item:first-child{
                border-radius: 14px 0 0 14px;
              }
              .item:last-child{
                border-radius: 0 14px 14px 0;
              }
            }
            .item {
              font-size: 14px;
              &>div {
                height: 49.5px;
              }
  
              .border {
                height: 28px;
                border: 1px solid #C5C5C5;
                border-radius: 14.5px;
                padding: 0 18px;
              }
            }
            .item:first-child{
              padding-left: 31.5px;
            }
            .item:last-child{
              padding-right: 31.5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    gap: 14px;
  
    .containerItem {
      .label {
        font-size: 12px;
        margin-bottom: 18px;
        svg {
          width: 15.5px;
          height: 15.5px;
          margin-right: 6.5px;
        }
      }
  
      .tableBlock {
        border-radius: 5.5px;
        padding: 0 5.5px;
        .tableHeader {
          font-size: 12px;
          .item {
            padding-top:11px;
            padding-right: 11px;
            padding-bottom: 9.5px;
          }
          .item:first-child{
            padding-left: 26.5px;
          }
  
          .item:last-child{
            padding-right: 26.5px;
          }
        }
  
        .tableBody {
          .itemRow {
            &.hover {
              .item:first-child{
                border-radius: 11px 0 0 11px;
              }
              .item:last-child{
                border-radius: 0 11px 11px 0;
              }
            }
            .item {
              font-size: 12px;
              &>div {
                height: 39px;
              }
  
              .border {
                height: 22px;
                border: 1px solid #C5C5C5;
                border-radius: 11.5px;
                padding: 0 14.5px;
              }
            }
            .item:first-child{
              padding-left: 24.5px;
            }
            .item:last-child{
              padding-right: 24.5px;
            }
          }
        }
      }
    }
  }
}