table {
  width: 100%;
  border-collapse: collapse;
  & > thead {
    font-size: 15px;
    font-family: "Roboto-Bold";
    & > tr {
      color: #414141;
      text-align: left;
      & > th:first-child {
        .headItem {
          padding-left: 38px;
        }
      }
      & > th {
        border: 0;
        padding: 0;
        .headItem {
          padding-right: 10px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            margin-left: 10px;
            svg {
              width: 14px;
            }
          }
        }
      }
    }
  }

  tbody {
    & > tr {
      & > td {
        border: 0;
        padding: 0;
        &:first-child {
          .bodyItem {
            padding-left: 38px;
            border-radius: 21px 0 0 21px;
            &:after {
              display: none;
            }
          }
        }
        &:last-child {
          .bodyItem {
            border-radius: 0 21px 21px 0;
            
          }
        }
        .bodyItem {
          background: #fff;
          padding-right: 10px;
          padding: 10px 10px 10px 0;
          min-height: 76px;
          box-shadow: 0px 10px 20px #00000029;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          position: relative;
          font-size: 20px;
          color: #7B7B7B;
          &:after {
            content: "";
            position: absolute;
            width: 25px;
            left: -25px;
            height: 100%;
            background: #fff;

          }
        }
      }
    }
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  table {
    & > thead {
      font-size: 12px;
      & > tr {
        & > th:first-child {
          .headItem {
            padding-left: 30.5px;
          }
        }
        & > th {
          .headItem {
            padding-right: 8px;
            padding-bottom: 12px;
            span {
              margin-left: 8px;
            }
          }
        }
      }
    }
  
    tbody {
      & > tr {
        & > td {
          &:first-child {
            .bodyItem {
              padding-left: 30.5px;
              border-radius: 17px 0 0 17px;
            }
          }
          &:last-child {
            .bodyItem {
              border-radius: 0 17px 17px 0;
            }
          }
          .bodyItem {
            padding-right: 8px;
            padding: 8px 8px 8px 0;
            min-height: 61px;
            margin-bottom: 9.5px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  table {
    & > thead {
      font-size: 12px;
      & > tr {
        & > th:first-child {
          .headItem {
            padding-left: 26.5px;
          }
        }
        & > th {
          .headItem {
            padding-right: 7px;
            padding-bottom: 10.5px;
            span {
              margin-left: 7px;
            }
          }
        }
      }
    }
  
    tbody {
      & > tr {
        & > td {
          &:first-child {
            .bodyItem {
              padding-left: 26.5px;
              border-radius: 14.5px 0 0 14.5px;
            }
          }
          &:last-child {
            .bodyItem {
              border-radius: 0 14.5px 14.5px 0;
            }
          }
          .bodyItem {
            padding-right: 7px;
            padding: 7px 7px 7px 0;
            min-height: 53px;
            margin-bottom: 8.5px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  table {
    & > thead {
      font-size: 12px;
      & > tr {
        & > th:first-child {
          .headItem {
            padding-left: 21px;
          }
        }
        & > th {
          .headItem {
            padding-right: 5.5px;
            padding-bottom: 8px;
            span {
              margin-left: 5.5px;
            }
          }
        }
      }
    }
  
    tbody {
      & > tr {
        & > td {
          &:first-child {
            .bodyItem {
              padding-left: 21px;
              border-radius: 11.5px 0 0 11.5px;
            }
          }
          &:last-child {
            .bodyItem {
              border-radius: 0 11.5px 11.5px 0;
            }
          }
          .bodyItem {
            padding-right: 5.5px;
            padding: 5.5px 5.5px 5.5px 0;
            min-height: 76px;
            margin-bottom: 42px;
            font-size: 12px;
          }
        }
      }
    }
  }
}