.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  font-family: Roboto-Regular;
  .backgtound {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #414141;
    opacity: .85;
  }

  .modal {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 0;
    border-radius: 10px;
    overflow-y: auto;
  }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    .modal {
      top: 160px;
      border-radius: 8px;
    }
  }    
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    .modal {
      top: 140px;
      border-radius: 7px;
    }
  }    
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    .modal {
      top: 110px;
      border-radius: 5.5px;
    }
  }    
}