.container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;

  .componentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-family: Roboto-Bold;
      font-size: 25px;
      color: #414141;
    }

    .headerButtons {
      display: flex;

      .headerButton {
        height: 33px;
        border: 1px solid #0ACF83;
        border-radius: 19px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        margin-left: 10px;
      }

      .unpressedButton {
        background-color: #FFFFFF;
        color: #414141;
        font-family: Roboto-Regular;
        font-size: 15px;
      }

      .pressedButton {
        background-color: #0ACF83;
        font-family: Heebo-Bold;
        font-size: 15px;
        color: #FFFFFF;
      }
    }
  }
}
 
@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    border-radius: 8px;
    padding: 16px;
  
    .componentHeader {
      margin-bottom: 16px;
  
      h3 {
        font-size: 20px;
      }
  
      .headerButtons {
        .headerButton {
          height: 26.5px;
          border-radius: 15px;
          padding: 0 16px;
        }
  
        .unpressedButton {
          font-size: 12px;
        }
  
        .pressedButton {
          font-size: 12px;
        }
      }
    }
  }
}
  
@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    border-radius: 7px;
    padding: 14px;
  
    .componentHeader {
      margin-bottom: 14px;
  
      h3 {
        font-size: 17.5px;
      }
  
      .headerButtons {
        .headerButton {
          height: 23px;
          border-radius: 13px;
          padding: 0 14px;
        }
  
        .unpressedButton {
          font-size: 12x;
        }
  
        .pressedButton {
          font-size: 12px;
        }
      }
    }
  }
}
  
@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    border-radius: 5.5px;
    padding: 11px;
  
    .componentHeader {
      margin-bottom: 11px;
  
      h3 {
        font-size: 14px;
      }
  
      .headerButtons {
        .headerButton {
          height: 18px;
          border-radius: 10.5px;
          padding: 0 11px;
        }
  
        .unpressedButton {
          font-size: 12px;
        }
  
        .pressedButton {
          font-size: 12px;
        }
      }
    }
  }
}            