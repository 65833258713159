.button {
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: #0ACF83 0% 0% no-repeat padding-box;
    border-radius: 19px;
    height: 34px;
    font-family: "Roboto-Regular";
    font-size: 13px;
    color: #fff;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 16px;
    }
    &:hover {
      background: #09b774;
      color: #fff;
    }
    &.icon {
      align-items: center;
    }
}

.greenBorder {
    box-sizing: border-box;
    display: flex;
    // align-items: flex-end;
    height: auto;
    white-space: nowrap;
    border: 2px solid #0ACF83;
    color: #414141;
    border-radius: 20px;
    padding: 9px 19px;
    background: #fff;
    transition: .3s;
    font-size: 15px;
    line-height: 15px;
    &:hover {
        background: #09b774;
        color: #fff;
    }
}

.red {
  border-color: red;
  background: #c40606;
  &:hover {
      background: #af0505;
  }
}

.lr {
    box-sizing: border-box;
    height: auto;
    white-space: nowrap;
    border-radius: 20px;
    padding: 11px 19px 7px;
    font-family: Roboto-Bold;
    font-size: 15px;
    line-height: 20px;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .button {
    border-radius: 15px;
    height: 27px;
    font-size: 12px;
    padding: 0 16px;
  }

  .greenBorder {
      border-radius: 16px;
      padding: 7px 15px;
      font-size: 12px;
      line-height: 12px;
  }

  .lr {
      border-radius: 16px;
      padding: 9px 15px 5.5px;
      font-size: 12px;
      line-height: 16px;
  }
  
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .button {
    border-radius: 13px;
    height: 24px;
    padding: 0 14px;
  }

  .greenBorder {
    border: 1px solid #0ACF83;
    border-radius: 14px;
    padding: 6px 13px;
  }

  .lr {
    border-radius: 14px;
    padding: 7.5px 13px 5px;
    line-height: 14px;
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .button {
    border-radius: 10.5px;
    height: 18.5px;
    padding: 0 11px;
  }

  .greenBorder {
      border-radius: 11px;
      padding: 5px 10.5px;
  }

  .lr {
      border-radius: 11px;
      padding: 6px 10.5px 4px;
      line-height: 11px;
  }
}