.menu {
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  min-height: 100%;
  z-index: 20;

  .mainMenu {
    width: 60px;
    display: flex;
    flex-direction: column;
    background: transparent linear-gradient(0deg, #414141 0%, #1A1A1A 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
    transition: .3s;
    justify-content: space-between;
  }

  .outsideBlock {
    display: flex;
  }

  &.open {
    .mainMenu {
      width: 201px;
    }

    .nameItem {
      opacity: 1;
      // max-width: 200px;
      display: inline-block !important;
    }

    .list,
    .bottomBlock {

      li,
      .bottomBlockItem {

        a,
        .bottomBlockIcon {
          border-radius: 22px;
          width: 100%;
        }

        &.active {
          .nameItem {
            color: #0acf83;
          }
        }
      }
    }
  }

  .openMenu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: #fff;

    &>svg {
      width: 20px;
      height: 20px;
    }
  }

  .nameItem {
    color: #fff;
    font-family: Roboto-Bold;
    white-space: nowrap;
    opacity: 0;
    // max-width: 0px;
    overflow: hidden;
    transition: .3s;
    display: inline-block;
    padding-left: 15px;
    line-height: 22px;
  }

  .list {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 0;
    font-size: 13px;
    padding: 0 8px;

    li {
      display: flex;
      justify-content: left;
      margin: 20px 0 0 0;

      a {
        max-width: 100%;
        display: flex;
        justify-content: left;
        // height: 43px;
        border-radius: 50%;
        transition: .3s;
        align-items: center;
        text-decoration: none;
        text-transform: none;
        padding: 9px 9px;
        box-sizing: border-box;
        white-space: nowrap;

        .nameItem {
          display: none;
        }

        &:hover {
          background: #0ACF83;

          svg,
          path {
            fill: #fff !important;
          }

          .nameItem {
            color: #fff !important;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          fill: #fff;
          display: inline-block;
        }
      }

      &.active {

        svg,
        path {
          fill: #0acf83;
        }
      }
    }
  }



  .farm {
    cursor: pointer;
  }

  .listFarms {
    background: #fff;
    font-family: Roboto-Regular;
    min-width: 150px;
    font-size: 13px;
    padding: 38px 20px;
    position: relative;
    z-index: 1;
    color: #000;
    text-decoration: 0;
    // cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 10px 10px 0px;
    height: 100%;

    .listFarmsButtons {
      border-top: 2px solid #000000;
      padding-top: 15px;
    }

    .listFarmsItem {
      a {
        text-decoration: none;
        color: #000;
      }

      &.active {
        color: #0ACF83;
        cursor: default;
      }
      cursor: pointer;
      margin-bottom: 15px;
    }
  }

  .bottomBlock {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 0;
    font-size: 13px;
    padding: 0 8px;
    margin-bottom: 126px;

    .bottomBlockItem {
      display: flex;
      justify-content: left;
      margin: 20px 0 0 0;

      .bottomBlockIcon {
        max-width: 100%;
        justify-content: left;
        height: 43px;
        border-radius: 50%;
        transition: .3s;
        text-decoration: none;
        text-transform: none;
        padding: 9px 9px;
        box-sizing: border-box;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background: #0ACF83;

          .nameItem {
            color: #fff !important;
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
        display: inline-block;
      }
    }
  }

}

.buttonFarm {
  margin-bottom: 10px;
}


@media (max-width: 1824px) {  // 95% screen 80% from base size if 100% is 1920px screen
  .menu {
    .mainMenu {
      width: 48px;
      border-radius: 0px 8px 8px 0px;
    }
  
    &.open {
      .mainMenu {
        width: 160.5px;
      }
  
      .list,
      .bottomBlock {
        li,
        .bottomBlockItem {
          a,
          .bottomBlockIcon {
            border-radius: 17.5px;
          }
        }
      }
    }
  
    .openMenu {
      &>svg {
        width: 16px;
        height: 16px;
      }
    }
  
    .nameItem {
      padding-left: 12px;
      line-height: 17.5px;
    }
 
    .list {
      font-size: 12px;
      padding: 0 8px;
  
      li {
        margin: 16px 0 0 0;
        a {
          padding: 7px 7px;
          svg {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  
    .listFarms {
      min-width: 120px;
      font-size: 12px;
      padding: 30.5px 16px;
      border-radius: 0px 8px 8px 0px;
  
      .listFarmsButtons {
        border-top: 2px solid #000000;
        padding-top: 12px;
      }
  
      .listFarmsItem {
        margin-bottom: 12px;
      }
    }
  
    .bottomBlock {
      font-size: 12px;
      padding: 0 6.5px;
      margin-bottom: 101px;

      .bottomBlockItem {
        margin: 16px 0 0 0;
  
        .bottomBlockIcon {
          height: 34.5px;
          padding: 7px 7px;
        }
  
        svg {
          width: 19px;
          height: 19px;
        }
      }
    }
  }  
}

@media (max-width: 1536px) {  // 80% screen 70% from base size if 100% is 1920px screen
  .menu {
    .mainMenu {
      width: 42px;
      border-radius: 0px 7px 7px 0px;
    }
  
    &.open {
      .mainMenu {
        width: 140.5px;
      }
  
      .list,
      .bottomBlock {
        li,
        .bottomBlockItem {
          a,
          .bottomBlockIcon {
            border-radius: 15.5px;
          }
        }
      }
    }
  
    .openMenu {
      &>svg {
        width: 14px;
        height: 14px;
      }
    }
  
    .nameItem {
      padding-left: 10.5px;
      line-height: 15.5px;
    }
 
    .list {
      padding: 0 5.5px;
  
      li {
        margin: 14px 0 0 0;
        a {
          padding: 6px 6px;
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  
    .listFarms {
      min-width: 105px;
      padding: 26.5px 14px;
      border-radius: 0px 7px 7px 0px;
  
      .listFarmsButtons {
        border-top: 1px solid #000000;
        padding-top: 10.5px;
      }
  
      .listFarmsItem {
        margin-bottom: 10.5px;
      }
    }
  
    .bottomBlock {
      padding: 0 5.5px;
      margin-bottom: 88px;

      .bottomBlockItem {
        margin: 14px 0 0 0;
  
        .bottomBlockIcon {
          height: 30px;
          padding: 6px 6px;
        }
  
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }  
}

@media (max-width: 1344px) {  // 70% screen 55% from base size if 100% is 1920px screen
  .menu {
    .mainMenu {
      width: 33px;
      border-radius: 0px 5.5px 5.5px 0px;
    }
  
    &.open {
      .mainMenu {
        width: 110.5px;
      }
  
      .list,
      .bottomBlock {
        li,
        .bottomBlockItem {
          a,
          .bottomBlockIcon {
            border-radius: 12px;
          }
        }
      }
    }
  
    .openMenu {
      &>svg {
        width: 11px;
        height: 11px;
      }
    }
  
    .nameItem {
      padding-left: 8px;
      line-height: 12px;
    }
 
    .list {
      padding: 0 4.4px;
  
      li {
        margin: 11px 0 0 0;
        a {
          padding: 5px 5px;
          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  
    .listFarms {
      min-width: 82.5px;
      padding: 21px 11px;
      border-radius: 0px 5.5px 5.5px 0px;
  
      .listFarmsButtons {
        padding-top: 8px;
      }
  
      .listFarmsItem {
        margin-bottom: 8px;
      }
    }
  
    .bottomBlock {
      padding: 0 4.5px;
      margin-bottom: 69px;

      .bottomBlockItem {
        margin: 11px 0 0 0;
  
        .bottomBlockIcon {
          height: 23.5px;
          padding: 5px 5px;
        }
  
        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }  
}