.container {
  box-sizing: border-box;
  display: flex;
  width: 50%;

  .title {
    font-size: 25px;
    margin-bottom: 12px;
    font-family: "Roboto-Bold";
    color: #414141;
  }

  .seporate {
    width: 2px;
    height: 100%;
    background: #9BA7A2;
    margin: 0 10px;
  }

  .sound{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px;
    margin-right: 10px;
    align-items: stretch;
    width: 45%;
    display: flex;
    flex-direction: column;
    .content {
      display: flex;
      justify-content: space-between;
    }
  }

  .windFeels {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 55%;
  }

  .content {
    .title {
      font-size: 25px;
      margin-bottom: 12px;
      font-family: "Roboto-Bold";
    }
    .block {
      .title {
        font-size: 15px;
        margin-bottom: 5px;
        font-family: "Roboto-Regular";
      }
      .value {
        color: #0ACF83;
        font-size: 15px;
        font-family: "Montserrat-Bold";
        &.pluss {
          color: #0ACF83;
        }
        &.minus {
          color: #992300;
        }
        span {
          font-size: 25px;
        }
      }
    }
  }
}  

.spinnerContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    .title {
      font-size: 20px;
      margin-bottom: 9.5px;
    }
  
    .seporate {
      margin: 0 8px;
    }
  
    .sound{
      border-radius: 8px;
      padding: 20px;
      margin-right: 8px;
    }
  
    .windFeels {
      border-radius: 8px;
      padding: 16px;
    }
  
    .content {
      .title {
        font-size: 20px;
        margin-bottom: 9.5px;
      }
      .block {
        .title {
          font-size: 12px;
          margin-bottom: 4px;
        }
        .value {
          font-size: 12px;
          span {
            font-size: 20px;
          }
        }
      }
    }
  } 
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    .title {
      font-size: 17.5px;
      margin-bottom: 8.5px;
    }
  
    .seporate {
      margin: 0 7px;
    }
  
    .sound{
      border-radius: 7px;
      padding: 14px;
      margin-right: 7px;
    }
  
    .windFeels {
      border-radius: 7px;
      padding: 14px;
    }
  
    .content {
      .title {
        font-size: 17.5px;
        margin-bottom: 8.5px;
      }
      .block {
        .title {
          font-size: 12px;
          margin-bottom: 4px;
        }
        .value {
          font-size: 12px;
          span {
            font-size: 17.5px;
          }
        }
      }
    }
  } 
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    .title {
      font-size: 14px;
      margin-bottom: 6.5px;
    }
  
    .seporate {
      margin: 0 5.5px;
    }
  
    .sound{
      border-radius: 5.5px;
      padding: 11px;
      margin-right: 5.5px;
    }
  
    .windFeels {
      border-radius: 5.5px;
      padding: 11px;
    }
  
    .content {
      .title {
        font-size: 14px;
        margin-bottom: 6.5px;
      }
      .block {
        .title {
          font-size: 12px;
          margin-bottom: 3px;
        }
        .value {
          font-size: 12px;
          span {
            font-size: 14px;
          }
        }
      }
    }
  } 
}