.selections {
  display: flex;
  align-items: center;
  font-family: Roboto-Regular;
  color: #414141;
  font-size: 15px;
}

.selectionItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
  .editButton {
    margin-left: 15px;
    width: 17px;
    height: 17px;
    cursor: pointer;
    &:hover::after {
      content: "";
      display: block;
      border-bottom: 1px solid #414141;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.selectionItemName {
  margin-right: 10px;
  white-space: nowrap;
}

.seporator {
  height: 31px;
  width: 2px;
  background: #000000;
  margin-right: 22px;
  margin-left: 8px;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .selections {
    font-size: 12px;
  }
  
  .selectionItem {
    margin-right: 16px;
    .editButton {
      width: 13.5px;
      height: 13.5px;
    }
  }
  
  .selectionItemName {
    margin-right: 8px;
  }
  
  .seporator {
    height: 25px;
    width: 2px;
    margin-right: 17.5px;
    margin-left: 6.5px;
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .selections {
    font-size: 12px;
  }
  
  .selectionItem {
    margin-right: 14px;
    .editButton {
      width: 12px;
      height: 12px;
    }
  }
  
  .selectionItemName {
    margin-right: 7px;
  }
  
  .seporator {
    height: 22px;
    width: 2px;
    margin-right: 15.5px;
    margin-left: 5.5px;
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .selections {
    font-size: 12px;
  }
  
  .selectionItem {
    margin-right: 11px;
    .editButton {
      width: 9.5px;
      height: 9.5px;
    }
  }
  
  .selectionItemName {
    margin-right: 5.5px;
  }
  
  .seporator {
    height: 17px;
    width: 2px;
    margin-right: 12px;
    margin-left: 4.5px;
  }
}