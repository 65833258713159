.input {
  border: 2px solid #414141;
  border-radius: 20px;
  padding: 0 19px;
  font-family: Roboto-Bold;
  width: auto;
  display: flex;
  align-items: center;
  max-width: 70px;
  // box-sizing: ;
  height: 34px;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .input {
    border-radius: 16px;
    padding: 0 15px;
    // max-width: 40px;
    height: 27px;
    font-size: 12px;
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .input {
    border: 1px solid #414141;
    border-radius: 14px;
    padding: 0 13px;
    height: 24px;
    // max-width: 12px;
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .input {
    border-radius: 11px;
    padding: 0 10.5px;
    height: 18.5;
    // max-width: 50px;
  }
}