.select {
    position: relative;
    margin-left: 5px;

    .selectName {
        // font-family: Roboto-Bold;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 18px;
        .downIcon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 18px;
        }
    }

    &.open {
        .selectName {
            color: #C4C4C4;
            padding-right: 18px;
            cursor: pointer;
            .downIcon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }
        .selectListRow {
            top: 100%;
            left: 0;
            min-height: 168px;
            overflow: hidden;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 0 0 20px 20px;
        }

        .selectList {
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100% + 18px);
            height: 100%;
            max-height: 133px;
            padding: 0 0 5px 10px;
            background: #fff;

        }
    }
}

.selectListRow {
    // transition: .3s;
    max-height: 38px;
    height: 38px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    cursor: pointer;
    padding: 5px 0 0 0;

    .selectList {
        background: transparent;
        max-height: 0px;
        // transition: .3s;
        overflow: hidden;
        padding: 0 0 0 19px;
    }
    .selectListItem {
        margin-bottom: 7px;
        font-size: 14px;
        word-break: break-all;
        cursor: pointer;
        width: 100%;
        &:hover {
            color: #0acf83;
        }
    }
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
    .select {
        margin-left: 4px;
    
        .selectName {
            padding-right: 14.5px;
            .downIcon {
                width: 14.5px;
            }
        }
    
        &.open {
            .selectName {
                padding-right: 14.5px;
            }
            .selectListRow {
                min-height: 168px;
                border-radius: 0 0 16px 16px;
            }
    
            .selectList {
                width: calc(100% + 14.5px);
                max-height: 106.5px;
                padding: 0 0 4px 8px;
            }
        }
    }
    
    .selectListRow {
        // transition: .3s;
        max-height: 30.5px;
        height: 30.5px;
        padding: 4px 0 0 0;
    
        .selectList {
            padding: 0 0 0 15px;
        }
        .selectListItem {
            margin-bottom: 5.5px;
            font-size: 12px;
        }
    }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
    .select {
        margin-left: 3.5px;
    
        .selectName {
            padding-right: 12.5px;
            .downIcon {
                width: 12.5px;
            }
        }
        
        &.open {
            .selectName {
                padding-right: 12.5px;
            }
            .selectListRow {
                min-height: 117.5px;
                border-radius: 0 0 14px 14px;
            }
    
            .selectList {
                width: calc(100% + 12.5px);
                max-height: 93px;
                padding: 0 0 3.5px 7px;
            }
        }
    }
    
    .selectListRow {
        // transition: .3s;
        max-height: 26.5px;
        height: 26.5px;
        padding: 3.5px 0 0 0;
    
        .selectList {
            padding: 0 0 0 13px;
        }
        .selectListItem {
            margin-bottom: 5px;
            font-size: 12px;
        }
    }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
    .select {
        margin-left: 3px;
    
        .selectName {
            padding-right: 10px;
            .downIcon {
                width: 10px;
            }
        }
    
        &.open {
            .selectName {
                padding-right: 10px;
            }
            .selectListRow {
                min-height: 92.5px;
                border-radius: 0 0 11px 11px;
            }
    
            .selectList {
                width: calc(100% + 10px);
                max-height: 73px;
                padding: 0 0 3px 5.5px;
            }
        }
    }
    
    .selectListRow {
        // transition: .3s;
        max-height: 21px;
        height: 21px;
        padding: 3px 0 0 0;
    
        .selectList {
            padding: 0 0 0 10.5px;
        }
        .selectListItem {
            margin-bottom: 4px;
            font-size: 12px;
        }
    }
}            