html,
head,
body,
form,
div,
h1,
h2,
h3,
h4,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
body, .container {
  height: 100%;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(./assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(./assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Heebo-Bold";
  src: url(./assets/fonts/Heebo-Bold.ttf);
}

@font-face {
  font-family: "Heebo-Medium";
  src: url(./assets/fonts/Heebo-Medium.ttf);
}

@font-face {
  font-family: "Heebo-Regular";
  src: url(./assets/fonts/Heebo-Regular.ttf);
}

.overflow-y-hidden {
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto-Regular";
}