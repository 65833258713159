.closeIconButton {
  position: absolute;
  width: 1.63rem;
  height: 1.63rem;
  border-radius: 50%;
  border: .13rem solid #414141;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &:hover {
    outline: .05rem solid #414141;
  }
}