.container {
  height: 61px;
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-left: 72px;
  padding-right: 81px;
  box-sizing: border-box;
  // position: relative;
  z-index: 10;
  box-shadow: 0px 10px 20px #0000001A;
}

.logo {
  height: 25px;
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    height: 49px;
    padding-left: 57.5px;
    padding-right: 65px;
  }
  
  .logo {
    height: 20px;
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    height: 42.5px;
    padding-left: 50.5px;
    padding-right: 56.5px;
  }
  
  .logo {
    height: 17.5px;
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    height: 33.5px;
    padding-left: 39.5px;
    padding-right: 44.5px;
  }
  
  .logo {
    height: 25px;
  }
}