.container {
  padding: 0;
  min-width: 608px;
  .header {
    display: flex;
    background: rgb(65,65,65);
    background: linear-gradient(237deg, rgba(65,65,65,1) 0%, rgba(26,26,26,1) 100%);
    color: #fff;
    flex-direction: column;
    padding: 20px 15px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      h3 {
        font-family: "Montserrat-Bold";
        font-size: 25px;
      }
      .close {
        cursor: pointer;
        font-size: 20px;
        color: #fff;
        transition: color 0.2s ease;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #FFFFFF;
        transform: rotate(-45deg);
        border-radius: 50%;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 48%;
      }
    }
  }
  .content {
    padding: 20px 15px;
    .contentItem {
      margin-bottom: 30px;
      .label {
        font-size: 18px;
        margin-bottom: 10px;
      }

      &> div {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 20px 15px;
    
  }
}


@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    min-width: 486.5px;
    .header {
      padding: 16px 12px;
  
      .top {
        margin-bottom: 12px;
        h3 {
          font-size: 20px;
        }
        .close {
          font-size: 16px;
          width: 21px;
          height: 21px;
          border: 2px solid #FFFFFF;
        }
      }
    }
    .content {
      padding: 16px 12px;
      .contentItem {
        margin-bottom: 30px;
        .label {
          font-size: 14.5px;
          margin-bottom: 8px;
        }
      }
    }
  
    .buttons {
      margin-top: 16px;
      padding: 16px 12px;
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    min-width: 425.5px;
    .header {
      padding: 14px 10.5px;
  
      .top {
        margin-bottom: 10.5px;
        h3 {
          font-size: 17.5px;
        }
        .close {
          font-size: 14px;
          width: 18px;
          height: 18px;
          border: 1px solid #FFFFFF;
        }
      }
    }
    .content {
      padding: 14px 10.5px;
      .contentItem {
        margin-bottom: 21px;
        .label {
          font-size: 12.5px;
          margin-bottom: 7px;
        }
      }
    }
  
    .buttons {
      margin-top: 14px;
      padding: 14px 10.5px;
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    min-width: 334.5px;
    .header {
      padding: 11px 8px;
  
      .top {
        margin-bottom: 8px;
        h3 {
          font-size: 14px;
        }
        .close {
          font-size: 11px;
          width: 14px;
          height: 14px;
          border: 1px solid #FFFFFF;
        }
      }
    }
    .content {
      padding: 11px 8px;
      .contentItem {
        margin-bottom: 16.5px;
        .label {
          font-size: 12px;
          margin-bottom: 5.5px;
        }
      }
    }
  
    .buttons {
      margin-top: 11px;
      padding: 11px 8px;
    }
  }
}