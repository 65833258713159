.content {
  padding: 0 20px;
  &.high {
    .itemContent {
      border-color: #992300;
    }
  }

  &.medium {
    .itemContent {
      border-color: #FF3A00;
    }
  }

  &.low {
    .itemContent {
      border-color: #FFB23A;
    }
  }
  .itemContent {
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border-left: 8px solid;
    border-radius: 5px;
    padding: 10px;
    gap: 20px;
    margin-bottom: 15px;
    .block {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &.right {
        width: 100%;
      }
      .checkbox {
        width: 17px;
        height: 17px;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;
        .statuses {
          display: flex;
          .status {
            font-size: 15px;
            display: flex;
            margin-right: 15px;
            svg {
              margin-right: 5px;
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
        .date {
          font-size: 12px;
          color: #414141;
          opacity: .7;
        }
      }
      .bottom {
        .desc {
          color: #414141;
          display: flex;
          align-items: center;
        }
        .alertsDetailsButton {
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          border: 2px solid #3D3D3D;
          border-radius: 50%;
          color: #3D3D3D;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Roboto-Bold;
          cursor: pointer;
        }
        .cycle {
          min-width: 23px;
          min-height: 23px;
          max-width: 23px;
          max-height: 23px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 8px;
          font-family: Montserrat-Bold;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
  
@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .content {
    padding: 0 16px;

    .itemContent {
      border-left: 6.5px solid;
      border-radius: 4px;
      padding: 8px;
      gap: 16px;
      margin-bottom: 12px;
      .block {
        .checkbox {
          width: 13.5px;
          height: 13.5px;
        }
        .top {
          margin-bottom: 12px;
          .statuses {
            .status {
              font-size: 12px;
              margin-right: 12px;
              svg {
                margin-right: 4px;
                
                max-width: 14.5px;
                max-height: 14.5px;
              }
            }
          }
          .date {
            font-size: 12px;
          }
        }
        .bottom {
          .alertsDetailsButton {
            min-width: 16px;
            min-height: 16px;
            max-width: 16px;
            max-height: 16px;
            border: 2px solid #3D3D3D;
          }
          .cycle {
            min-width: 18.5px;
            min-height: 18.5px;
            max-width: 18.5px;
            max-height: 18.5px;
            border-radius: 50%;
            margin-right: 6.5px;
          }
        }
      }
    }
  }
}
  
@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .content {
    padding: 0 14px;

    .itemContent {
      border-left: 5.5px solid;
      border-radius: 3.5px;
      padding: 7px;
      gap: 14px;
      margin-bottom: 10.5px;
      .block {
        .checkbox {
          width: 12px;
          height: 12px;
        }
        .top {
          margin-bottom: 10.5px;
          .statuses {
            .status {
              font-size: 10.5px;
              margin-right: 10.5px;
              svg {
                margin-right: 3.5px;
                max-width: 12.5px;
                max-height: 12.5px;
              }
            }
          }
        }
        .bottom {
          .alertsDetailsButton {
            min-width: 14px;
            min-height: 14px;
            max-width: 14px;
            max-height: 14px;
            border: 1px solid #3D3D3D;
          }
          .cycle {
            max-width: 16px;
            max-height: 16px;
            min-width: 16px;
            min-height: 16px;
            margin-right: 5.5px;
          }
        }
      }
    }
  }
}
  
@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .content {
    padding: 0 11px;

    .itemContent {
      box-shadow: 0px 3px 6px #00000029;
      border-left: 4.5px solid;
      border-radius: 3px;
      padding: 5.5px;
      gap: 11px;
      margin-bottom: 9px;
      .block {
        .checkbox {
          width: 9px;
          height: 9px;
        }
        .top {
          margin-bottom: 9px;
          .statuses {
            .status {
              font-size: 12px;
              margin-right: 9px;
              svg {
                margin-right: 3px;
                max-width: 10px;
                max-height: 10px;
              }
            }
          }
        }
        .bottom {
          .alertsDetailsButton {
            min-width: 11px;
            min-height: 11px;
            max-width: 11px;
            max-height: 11px;
            border: 1px solid #3D3D3D;
          }
          .cycle {
            min-width: 12.5px;
            min-height: 12.5px;
            max-width: 12.5px;
            max-height: 12.5px;
            margin-right: 4.5px;
          }
        }
      }
    }
  }
}    