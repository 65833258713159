.inputLabel {
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    font-family: Roboto-Bold;
    font-size: .94rem;
    line-height: 1.13rem;
    color: #414141;
    margin-bottom: .43rem;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    height: 1.88rem;
    padding-inline-start: .75rem;
    outline: #414141 solid .06rem;
    border: none;
    border-radius: 1.19rem;
    font-family: Roboto-Regular;
    font-size: .93rem;

    &:focus {
      outline: #414141 solid .13rem;
    }

    &:hover {
      outline: #414141 solid .13rem;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  .errorMessage {
    position: absolute;
    color: red;
    font-family: Roboto-Regular;
    font-size: 0.74rem;
    padding-inline-start: .2rem;
    bottom: -1rem;
  }
}