.selectContent {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
  }
  .select {
    border: 1px solid #414141;
    border-radius: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 10px;
    position: relative;
    background: #fff;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      right: 10px;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-bottom: 1px solid #414141;
      border-right: 1px solid #414141;
      transform: rotate(45deg);
      cursor: pointer;
    }
    .selected {
      font-size: 15px;
      color: #414141;
      cursor: pointer;
    
      &.placeholder {
        color: #C4C4C4;
        font-size: 15px;
      }
    }
    .list {
      position: absolute;
      top: 100%;
      height: 0;
      width: 100%;
      left: 0;
      background: #fff;
      color: #414141;
      font-size: 15px;
      border: 1px solid #C4C4C4;
      opacity: 0;
      &.open {
        opacity: 1;
        height: auto;
      }
      .item {
        padding: 5px 10px;
        border-bottom: 1px solid #C4C4C4;
        cursor: pointer;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }    
}

@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .selectContent {
    label {
      margin-bottom: 8px;
    }
    .select {
      border-radius: 12px;
      height: 24px;
      padding: 0px 16px 0px 8px;
      &:after {
        content: "";
        top: calc(50% - 4px);
        right: 8px;
        width: 8px;
        height: 8px;
      }
      .selected {
        font-size: 12px;
        &.placeholder {
          font-size: 12px;
        }
      }
      .list {
        font-size: 12px;
        .item {
          padding: 4px 8px;
        }
      }
    }    
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .selectContent {
    label {
      margin-bottom: 7px;
    }
    .select {
      border-radius: 10.5px;
      height: 21px;
      padding: 0px 14px 0px 7px;
      &:after {
        content: "";
        top: calc(50% - 3.5px);
        right: 7px;
        width: 3.5px;
        height: 3.5px;
      }
      .selected {
        font-size: 12px;
        &.placeholder {
          font-size: 12px;
        }
      }
      .list {
        font-size: 12px;
        .item {
          padding: 3.5px 7px;
        }
      }
    }    
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .selectContent {
    label {
      margin-bottom: 5.5px;
    }
    .select {
      border-radius: 8px;
      height: 16.5px;
      padding: 0px 11px 0px 5.5px;
      &:after {
        content: "";
        top: calc(50% - 3px);
        right: 5.5px;
        width: 3px;
        height: 3px;
      }
      .selected {
        font-size: 12px;
        &.placeholder {
          font-size: 12px;
        }
      }
      .list {
        font-size: 12px;
        .item {
          padding: 3px 5.5px;
        }
      }
    }    
  }
}