.container {
  width: 50%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  font-family: Roboto-Regular;


  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    h3 {
      font-family: Roboto-Bold;
      font-size: 25px;
      color: #414141;
    }

    .buttons {
      display: flex;

      button {
        height: 33px;
        border: 1px solid #0ACF83;
        border-radius: 19px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
      }

      .pressed {
        background-color: #0ACF83;
        font-family: Heebo-Bold;
        font-size: 15px;
        color: #FFFFFF;
      }

      .unpressed {
        background-color: #FFFFFF;
        color: #414141;
        font-family: Roboto-Regular;
        font-size: 15px;
        margin-left: 5px;
      }

      .averageButton {
        margin-inline-start: .4rem;
      }
    }
  }

  .content {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    position: relative;
    z-index: 10;
    .hint {
      font-family: Roboto-Regular;
      font-size: 15px;
      position: absolute;
      top: 100%;
      padding-top: 3px;
      left: 50%;
      transform: translate(-50%);
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 20px;
      //   width: 0;
      //   height: 0;
      //   border: 10px solid ;
      //   border-color: transparent transparent #fff transparent;
      // }
    }

    .bar {
      flex-grow: 1;
      display: flex;
      position: relative;
      height: 12px;
      background: transparent linear-gradient(90deg, #0ACF83F8 0%, var(--unnamed-color-ffb23a) 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(90deg, #0ACF83F8 0%, #FFB23A 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
      border-radius: 17px;

      .tip {
        position: absolute;
        width: 4px;
        height: 24px;
        background-color: #414141;
        border-radius: 2px;
        top: 50%;
        transform: translateY(-50%);

        &:hover .popup {
          display: flex;
        }

        .popup {
          display: none;
          position: absolute;
          top: calc(100% + 21px);
          left: 0;
          width: 476px;
          min-height: 155px;
          flex-direction: column;
          box-shadow: 0px -3px 20px #00000029;
          background: #fff;
          padding: 10px;
          z-index: 10;
          font-size: 18px;
          b {
            font-size: 20px;
          }
          .popupHeader {
            display: flex;
            text-wrap: nowrap;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px solid #707070;
            margin-bottom: 10px;
            div {
              display: flex;
              width: 50%;
            }
            b {
              margin-left: 5px;
            }
          }
          .popupContent {
            display: flex;
            flex-direction: column;
            div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.containerForSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


@media (max-width: 1824px) { // 95% screen 80% from base size if 100% is 1920px screen
  .container {
    border-radius: 8px;
    padding: 6px;
  
    .header {
      margin-bottom: 32px;
  
      h3 {
        font-size: 20px;
      }
  
      .buttons {
  
        button {
          height: 26.5px;
          border-radius: 15px;
          padding: 0 16px;
        }
  
        .pressed {
          font-size: 12px;
        }
  
        .unpressed {
          color: #414141;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  
    .content {
      margin: 16px 0;
      height: 19px;
  
      .hint {
        font-size: 12px;
        top: 100%;
        padding-top: 3px;
        left: 50%;
      }
  
      .bar {
        flex-grow: 1;
        height: 9.5px;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, var(--unnamed-color-ffb23a) 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, #FFB23A 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        border-radius: 13.5px;
  
        .tip {
          width: 3px;
          height: 19px;
          border-radius: 2px;

          .popup {
            top: calc(100% + 17px);
            width: 381px;
            min-height: 124px;
            padding: 8px;
            font-size: 14.5px;
            b {
              font-size: 16px;
            }
            .popupHeader {
              padding-bottom: 8px;
              margin-bottom: 8px;
            }
            .popupContent {
              div {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1536px) { // 80% screen 70% from base size if 100% is 1920px screen
  .container {
    border-radius: 7px;
    padding: 14px;
  
    .header {
      margin-bottom: 28px;
  
      h3 {
        font-size: 17.5px;
      }
  
      .buttons {
  
        button {
          height: 23px;
          border-radius: 13px;
          padding: 0 14px;
        }
  
        .pressed {
          font-size: 12px;
        }
  
        .unpressed {
          color: #414141;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  
    .content {
      margin: 14px 0;
      height: 17px;
  
      .hint {
        font-size: 12px;
        top: 100%;
        padding-top: 3px;
        left: 50%;
      }
  
      .bar {
        flex-grow: 1;
        height: 8.5px;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, var(--unnamed-color-ffb23a) 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, #FFB23A 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        border-radius: 12px;
  
        .tip {
          width: 3px;
          height: 17px;
          border-radius: 2px;

          .popup {
            top: calc(100% + 17px);
            width: 333px;
            min-height: 108.5px;
            padding: 7px;
            font-size: 12.5px;
            b {
              font-size: 12.5px;
            }
            .popupHeader {
              padding-bottom: 7px;
              margin-bottom: 7px;
            }
            .popupContent {
              div {
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1344px) { // 70% screen 55% from base size if 100% is 1920px screen
  .container {
    border-radius: 5.5px;
    padding: 11px;
  
    .header {
      margin-bottom: 22px;
  
      h3 {
        font-size: 14px;
      }
  
      .buttons {
  
        button {
          height: 18px;
          border-radius: 10.5px;
          padding: 0 11px;
        }
  
        .pressed {
          font-size: 12px;
        }
  
        .unpressed {
          color: #414141;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  
    .content {
      margin: 11px 0;
      height: 13.2px;
  
      .hint {
        font-size: 12px;
        top: 100%;
        padding-top: 3px;
        left: 50%;
      }
  
      .bar {
        flex-grow: 1;
        height: 6.5px;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, var(--unnamed-color-ffb23a) 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(90deg, #0ACF83F8 0%, #FFB23A 21%, #F58840 45%, #FF3A00 64%, #CC4118 83%, #992300 100%) 0% 0% no-repeat padding-box;
        border-radius: 9px;
  
        .tip {
          width: 2px;
          height: 13px;
          border-radius: 1px;

          .popup {
            top: calc(100% + 17px);
            width: 262px;
            min-height: 85px;
            padding: 5.5px;
            font-size: 12px;
            b {
              font-size: 12px;
            }
            .popupHeader {
              padding-bottom: 5.5px;
              margin-bottom: 5.5px;
            }
            .popupContent {
              div {
                margin-bottom: 5.5px;
              }
            }
          }
        }
      }
    }
  } 
}