.helperBlock {
  svg {
    width: 300px;
    box-shadow: #fff 0px 0px 0px 0px;
    position: absolute;
    top: 4px;
    left: 5%;
  }
}
.container {
  box-sizing: border-box;
  max-width: 100vw;
  display: flex;

  .formPart {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38%;
    padding-inline-start: 2rem;

    .loginForm {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 19.69rem;

      h2 {
        margin: 0;
        font-family: Montserrat-Bold;
        font-size: 1.56rem;
        line-height: 1.88rem;
        color: #414141;
      }

      .userNameInputContainer {
        margin-bottom: 2.25rem;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .imagePart {
    position: relative;
    width: 62%;
    overflow: hidden;
    height: 100vh;

    .helperBlock {
      position: absolute;
      background-color: #079e64;
      height: 100%;
      width: 100%;
      left: 7%
    }

    .helperBlockRTL {
      right: 7%
    }

    img {
      position: absolute;
      bottom: -0.4%;
      width: 108%;
    }

    .imgLTR {
      transform: scale(-1, 1);
    }
  }
}